import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import InnerPage from "../../components/innerPage";
import useSWR from "swr";
import {graphqlFetcher} from "../../modules/swrFetchers";
import parse from "html-react-parser";
import {DateTime} from "luxon";
import LoadingIndicator from "../../components/loadingIndicator";
import ErrorBlock from "../../components/errorBlock";

const queryString = require('query-string');

/**
 * Article Page
 * @returns {JSX.Element}
 * @private
 */
const Article = (props) => {
    const {t} = useTranslation();
    const {language, languages, originalPath} = useI18next();

    const {
        location // ?type=[news|event-highlights] & id=<string>
    } = props

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: t('media'),
            url: "/media"
        }
    ])
    const [pageTitle, setPageTitle] = useState(t('loading'));
    const [articleUrl, setArticleUrl] = useState("");
    const [articleId, setArticleId] = useState("")
    const [articleDate, setArticleDate] = useState(DateTime.now())

    const [articleContent, setArticleContent] = useState();
    const [isError, setIsError] = useState(false);
    const pageGraphQL = `
    query GetPost($id: ID!) {
      post(id: $id) {
        content
        title
        date
      }
    }
    `
    const pageGraphQLVar = {
        id: articleId,
    }
    const { data, error } = useSWR([pageGraphQL, pageGraphQLVar], graphqlFetcher)

    useEffect(() => {
        // set article url
        setArticleUrl(`${location.pathname}${location.search}`)

        // console.log(location.search)
        const parsed = queryString.parse(location.search);
        // console.log(parsed)

        if (!parsed?.type || !parsed?.id) {
            setIsError(true)
            return;
        }

        const bcNewEl = [
            {
                title: t(parsed?.type === "event-highlights" ? "eventHighlights" : parsed.type),
                url: `/media/${parsed.type}`
            },
            {
                title: t('article')
            }
        ]
        setBreadcrumbs(prevArray => [...prevArray, ...bcNewEl])
        setArticleId(parsed.id);
        // console.log(breadcrumbs)
    }, [location])

    useEffect(() => {
        if (data?.post?.content && data?.post?.title) {
            setArticleContent(data.post.content)
            setPageTitle(data.post.title)
            setArticleDate(DateTime.fromISO(data.post.date))
            setIsError(false);
        }
        else {
            if (data) {
                setIsError(true)
            }
        }

    }, [data])

    return (
        <Layout>
            <SEO title={pageTitle}/>
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={pageTitle}
                mode={"blog"}
            >
                <div className="row">
                    {/*<div className="col-lg-8 entries">*/}
                    <div className="col entries">
                        <article className="entry entry-single">

                            {/*<div className="entry-img">*/}
                            {/*    <img src="assets/img/blog/blog-1.jpg" alt="" className="img-fluid"/>*/}
                            {/*</div>*/}

                            {!data && !error && (
                                <>
                                    <LoadingIndicator />
                                </>
                            )}

                            {(error || isError) && (
                                <>
                                    <ErrorBlock />
                                </>
                            )}

                            {articleContent && (
                                <>
                                    <h2 className="entry-title">
                                        {/*<Link to={articleUrl}>*/}
                                            {parse(pageTitle)}
                                        {/*</Link>*/}
                                    </h2>

                                    <div className="entry-meta">
                                        <ul>
                                            {/*<li className="d-flex align-items-center"><i className="bi bi-person"></i> <a*/}
                                            {/*    href="blog-single.html">John Doe</a></li>*/}
                                            <li className="d-flex align-items-center">
                                                <i className="bi bi-clock"></i>
                                                {/*<Link to={articleUrl}>*/}
                                                    <time dateTime={articleDate.toISO()}>{articleDate.toLocaleString()}</time>
                                                {/*</Link>*/}
                                            </li>
                                            {/*<li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a*/}
                                            {/*    href="blog-single.html">12 Comments</a></li>*/}
                                        </ul>
                                    </div>

                                    <div className="entry-content">
                                        {parse(articleContent)}
                                    </div>

                                    {/*<div className="entry-footer">*/}
                                    {/*    <i className="bi bi-folder"></i>*/}
                                    {/*    <ul className="cats">*/}
                                    {/*        <li><a href="#">Business</a></li>*/}
                                    {/*    </ul>*/}

                                    {/*    <i className="bi bi-tags"></i>*/}
                                    {/*    <ul className="tags">*/}
                                    {/*        <li><a href="#">Creative</a></li>*/}
                                    {/*        <li><a href="#">Tips</a></li>*/}
                                    {/*        <li><a href="#">Marketing</a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </>
                            )}

                        </article>
                        {/*End blog entry*/}
                    </div>
                </div>
            </InnerPage>
        </Layout>
    );
};

export default Article;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;